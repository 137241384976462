import Spinner from 'react-bootstrap/Spinner';
import { useParams } from 'react-router-dom';
import { addNewGroupToDso, editDsoGroupName } from '../../../../../../actions/dsosActions';
import { BorderedTable } from '../../BorderedTable/BorderedTable';
import { Header } from '../../BorderedTable/Header';
import { Item } from '../../BorderedTable/Item';
import { useDsoGroupList } from './hooks';

export const GroupsTab = () => {
  const { id: dsoId } = useParams();
  const { data, total, isLoading, handleScrollList, isInitialLoading } = useDsoGroupList(dsoId);

  return (
    <div
      className="card mt-3 ml-4 mr-4 mb-3"
      style={{
        display: 'flex',
        width: '80%',
        paddingBottom: 80,
      }}
    >
      <span
        style={{
          fontSize: 24,
          marginTop: 20,
          textAlign: 'center',
        }}
      >
        Groups
      </span>
      <div
        style={{
          margin: '0 80px',
        }}
      >
        {isInitialLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 400,
            }}
          >
            <Spinner
              animation="border"
              role="status"
              variant="info"
              style={{
                height: 40,
                width: 40,
              }}
            />
          </div>
        )}
        {!isInitialLoading && (
          <>
            <Header action={addNewGroupToDso} label="Group" total={total} />
            <BorderedTable
              title="Groups"
              emptyText="There are no groups"
              data={data}
              onScroll={handleScrollList}
              isLoading={isLoading}
              Item={({ item }) => <Item editAction={editDsoGroupName} label="Group" item={item} />}
            />
          </>
        )}
      </div>
    </div>
  );
};
