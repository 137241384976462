import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDsoById } from '../../../../actions/dsosActions';
import { createLoadingSelector } from '../../../../apis/selectors';

const actions = ['FETCH_DSO_BY_ID'];
const loadingSelector = createLoadingSelector(actions);

export const useDsoGroupDetails = (dsoId) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dsos.dsoGroupDetails);
  const isLoading = useSelector((state) => loadingSelector(state));
  const [selectedTab, setSelectedTab] = useState('Regions');

  const handleTabSelection = (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    dispatch(fetchDsoById(dsoId));
  }, [dispatch, dsoId]);

  return {
    data,
    isLoading,
    selectedTab,
    handleTabSelection,
  };
};
