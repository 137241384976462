import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchDsosList } from '../../../../../actions/dsosActions';
import { createLoadingSelector } from '../../../../../apis/selectors';
import history from '../../../../../history';
import { dsosListColumn } from '../utils';

const actions = ['FETCH_DSOS_LIST'];
const loadingSelector = createLoadingSelector(actions);

export const useDsosListTable = () => {
  const dispatch = useDispatch();
  const { filters, total, data } = useSelector((state) => state.dsos.dsosList);
  const getColumns = () => dsosListColumn();
  const totalPages = Math.ceil(total / filters.limit);
  const isLoading = useSelector((state) => loadingSelector(state));

  const handleTableChange = (page) => {
    dispatch(fetchDsosList({ page }));
  };

  const onRowClicked = (row) => {
    history.push(`/dsos/${row.id}`);
  };

  const handleFilterChange = (type, value) => {
    dispatch(fetchDsosList({ [type]: value, page: 1 }));
  }

  useEffect(() => {
    dispatch(fetchDsosList({ page: 1 }));
  }, [dispatch]);

  return {
    filters,
    handleTableChange,
    columns: getColumns(),
    onRowClicked,
    handleFilterChange,
    data,
    total,
    totalPages,
    isLoading,
    limit: filters.limit,
  };
};
